import React, { useEffect, useRef, useState } from "react";
import "./dropdown.min.css";

const Dropdown = ({ id, testId, buttonContent, children, hideDropdown, setHideDropdown, image }) => {
	const [isVisible, setIsVisible] = useState(false);
	const dropdownRef = useRef();

	const toggleVisibility = () => setIsVisible(!isVisible);

	const handleClickOutside = (event) => {
		if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
			setIsVisible(false);
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	useEffect(() => {
		if (hideDropdown) {
			setIsVisible(false);
			setHideDropdown(false);
		}
	}, [hideDropdown, setHideDropdown]);

	return (
		<div className={`dropdown ${isVisible ? "active" : ""}`} id={id} data-test-id={testId} ref={dropdownRef}>
			<button onClick={toggleVisibility}>
				{image && <img src={image} alt="" className="courseImage" />}
				{buttonContent}
			</button>
			{isVisible && <div className="dropdownMenu">{children}</div>}
		</div>
	);
};

export default Dropdown;
