export const disableScroll = () => {
	const scrollY = `${window.scrollY}px`;
	document.body.style.position = "fixed";
	document.body.style.top = `-${scrollY}`;
};

export const enableScroll = () => {
	const scrollY = document.body.style.top;
	document.body.style.position = "";
	document.body.style.top = "";
	window.scrollTo(0, parseInt(scrollY || "0") * -1);
};
