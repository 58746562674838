import React from "react";

const LoadingSkeleton = () => (
	<div id="skeletonLoadingContainer">
		{Array.from({ length: 12 }).map((_, i) => (
			<div key={i} className="skeletonLoading"></div>
		))}
	</div>
);

export default LoadingSkeleton;
