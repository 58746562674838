// src/components/TermsOfService.js
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./termsOfService.css";

const TermsOfService = () => {
	useEffect(() => {
		const container = document.getElementById("enzuzo-tos-container");
		if (container) {
			const script = document.createElement("script");
			script.src = "https://app.enzuzo.com/scripts/tos/47337b00-df30-11ee-812d-d336526ba4e9";
			script.async = true;
			script.id = "__enzuzo-tos-script";
			container.appendChild(script);
		} else {
			console.error("enzuzo-tos-container element not found");
		}
	}, []);

	return (
		<div>
			<div id="enzuzo-tos-container"></div>
			<div className="back-home-container">
				<Link to="/home" className="back-home-link">
					Back to Home
				</Link>
			</div>
		</div>
	);
};

export default TermsOfService;
