import axios from "axios";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import logo from "../../assets/images/logo-small.png";
import { useNotice } from "../../context/NoticeContext";

const UpdatePassword = () => {
	const [password, setPassword] = useState("");
	const { addNotice } = useNotice();

	const navigate = useNavigate();

	const { token } = useParams();

	const handleResetPassword = async (e) => {
		e.preventDefault();
		try {
			await axios.put(`${process.env.REACT_APP_API_URL}/updatepassword/${token}`, {
				password,
			});
			addNotice({ type: "success", message: "Your password has been reset" });
			navigate("/login");
		} catch (err) {
			addNotice({ type: "error", message: err.response.data });
		}
	};

	return (
		<div className="authenticationContainer">
			<a href="/">
				<img id="logo" src={logo} alt="Logo" />
			</a>
			<div className="authenticationContainerInner">
				<h1>Reset your password</h1>
				<form onSubmit={handleResetPassword}>
					<input
						type="password"
						placeholder="Enter new password"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
						required
					/>
					<button className="button-large button-primary button-primary-fill" type="submit">
						Reset Password
					</button>
				</form>
			</div>
			<div></div>
		</div>
	);
};

export default UpdatePassword;
