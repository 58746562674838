import React, { createContext, useContext, useState, useCallback } from "react";

const NoticeContext = createContext();

export const useNotice = () => useContext(NoticeContext);

const NoticeProvider = ({ children }) => {
	const [notices, setNotices] = useState([]);

	const addNotice = useCallback((notice) => {
		const id = Math.random().toString(36).substr(2, 9);
		setNotices((prevNotices) => [...prevNotices, { ...notice, id, persistent: false }]);

		setTimeout(() => {
			removeNotice(id);
		}, 5000);

		return id;
	}, []);

	const addPersistentNotice = useCallback((notice) => {
		const id = Math.random().toString(36).substr(2, 9);
		setNotices((prevNotices) => [...prevNotices, { ...notice, id, persistent: true }]);
		return id;
	}, []);

	const removeNotice = useCallback((id) => {
		setNotices((prevNotices) => prevNotices.filter((notice) => notice.id !== id));
	}, []);

	const value = {
		notices,
		addNotice,
		addPersistentNotice,
		removeNotice,
	};

	return <NoticeContext.Provider value={value}>{children}</NoticeContext.Provider>;
};

export default NoticeProvider;
