import { faCheck, faMinus, faPlus } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Question from "../Question";
import "./category.min.css";

const Category = ({
	openQuestionModal,
	categoryId,
	categoryData,
	expandedCategories,
	toggleCategoryExpanded,
	userData,
}) => {
	const isPremiumUser = userData.isPremium;
	const isPremiumCategory = categoryData.premium;
	const correctlyAnswered = categoryData.questions.filter((q) => q.isCorrectlyAnswered).length;
	const categoryNotStarted = correctlyAnswered === 0;
	const categoryAnsweredRatio = `${correctlyAnswered} / ${categoryData.questions.length}`;
	const categoryComplete = correctlyAnswered === categoryData.questions.length;

	const showCategoryProgress = !categoryNotStarted;
	const showCategoryAccessibleStatus = !isPremiumUser && (isPremiumCategory || categoryNotStarted);

	return (
		<div id={categoryId} className={`category ${expandedCategories[categoryId] ? "categoryExpanded" : ""}`}>
			<div className="topContainer" onClick={() => toggleCategoryExpanded(categoryId)}>
				<h2>
					{expandedCategories[categoryId] ? (
						<FontAwesomeIcon icon={faMinus} className="icon" />
					) : (
						<FontAwesomeIcon icon={faPlus} className="icon" />
					)}
					{categoryData.title}
				</h2>
				<div className="statusAndProgress">
					{showCategoryAccessibleStatus && (
						<div className={`categoryAccessibleStatus ${isPremiumCategory ? "premiumCategory" : "freeCategory"}`}></div>
					)}
					{showCategoryProgress && (
						<div className={`categoryProgress ${categoryComplete ? "categoryComplete" : "categoryAnsweredRatio"}`}>
							{categoryComplete && !isPremiumCategory && <FontAwesomeIcon icon={faCheck} className="icon" />}
							{categoryComplete && isPremiumCategory && isPremiumUser && (
								<FontAwesomeIcon icon={faCheck} className="icon" />
							)}
							{correctlyAnswered > 0 &&
								!categoryComplete &&
								((isPremiumUser && isPremiumCategory) || !isPremiumCategory) &&
								categoryAnsweredRatio}
						</div>
					)}
				</div>
			</div>
			{expandedCategories[categoryId] && (
				<ul>
					{categoryData.questions.map((question, index) => (
						<Question key={index} question={question} openQuestionModal={openQuestionModal} userData={userData} />
					))}
				</ul>
			)}
		</div>
	);
};

export default Category;
