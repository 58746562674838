import { closeBrackets, closeBracketsKeymap } from "@codemirror/autocomplete";
import { defaultKeymap, indentWithTab } from "@codemirror/commands";
import { javascript } from "@codemirror/lang-javascript";
import { EditorState, RangeSetBuilder, StateEffect, StateField } from "@codemirror/state";
import { oneDark } from "@codemirror/theme-one-dark";
import { Decoration, EditorView, highlightActiveLine, keymap } from "@codemirror/view";
import React, { useCallback, useEffect, useRef } from "react";
import "./codeEditor.min.css";

const updateDecorationsEffect = StateEffect.define();
const highlightCustomLinesField = StateField.define({
	create() {
		return Decoration.none;
	},
	update(decorations, tr) {
		for (let effect of tr.effects) {
			if (effect.is(updateDecorationsEffect)) {
				return effect.value;
			}
		}
		return decorations.map(tr.changes);
	},
	provide: (field) => EditorView.decorations.from(field),
});

const computeHighlightCustomLines = (state) => {
	const lineClasses = {
		"//": {
			"✓": "correct-line",
			"✗": "incorrect-line",
			"Example Solution": "solution-line",
			"Correct Answer": "solution-line",
		},
	};

	const builder = new RangeSetBuilder();
	let inMultilineComment = false;

	for (let pos = 0; pos < state.doc.length; ) {
		const line = state.doc.lineAt(pos);
		let lineText = line.text;

		if (lineText.includes("/*")) {
			inMultilineComment = true;
		}

		if (inMultilineComment) {
			builder.add(line.from, line.from, Decoration.line({ class: "assistant-line" }));
		}

		if (lineText.includes("*/")) {
			inMultilineComment = false;
		}

		for (const [prefix, subClasses] of Object.entries(lineClasses)) {
			if (lineText.startsWith(prefix)) {
				for (const [subPrefix, className] of Object.entries(subClasses)) {
					if (lineText.startsWith(prefix + " " + subPrefix)) {
						builder.add(line.from, line.from, Decoration.line({ class: className }));
						break;
					}
				}
				break;
			}
		}

		pos = line.to + 1;
	}

	return builder.finish();
};

const deleteMatchingBracket = (view) => {
	const { state, dispatch } = view;
	const selection = state.selection.main;
	const selectedText = state.sliceDoc(selection.from - 1, selection.from);
	const nextChar = state.sliceDoc(selection.from, selection.from + 1);

	const brackets = {
		"(": ")",
		"[": "]",
		"{": "}",
	};

	const openingBracket = selectedText;
	const closingBracket = brackets[openingBracket];

	if (closingBracket && nextChar === closingBracket) {
		dispatch(
			state.update({
				changes: {
					from: selection.from - 1,
					to: selection.from + 1,
				},
				selection: {
					anchor: selection.from - 1,
					head: selection.from - 1,
				},
			})
		);
		return true;
	}

	return false;
};

const customKeymap = keymap.of([
	{
		key: "Backspace",
		run: deleteMatchingBracket,
	},
	...closeBracketsKeymap,
]);

const CodeEditor = ({
	id,
	code,
	setCode,
	className,
	isEditable = true,
	currentQuestionIndex,
	assistantResponse,
	handleCtrlEnter,
	focusTrigger,
}) => {
	const editorRef = useRef();
	const viewRef = useRef();

	const setCursorAtEnd = (view) => {
		const end = view.state.doc.length;
		view.dispatch({
			selection: { anchor: end },
		});
		view.focus();
	};

	const handleKeyDown = useCallback(
		(e) => {
			if (e.ctrlKey && e.key === "Enter" && className.includes("userEditor")) {
				e.preventDefault();
				handleCtrlEnter();
			}
		},
		[handleCtrlEnter, className]
	);

	useEffect(() => {
		document.addEventListener("keydown", handleKeyDown);
		return () => {
			document.removeEventListener("keydown", handleKeyDown);
		};
	}, [handleKeyDown]);

	useEffect(() => {
		if (!editorRef.current) return;

		const startState = EditorState.create({
			doc: code,
			extensions: [
				closeBrackets(),
				customKeymap,
				keymap.of([...defaultKeymap, indentWithTab]),
				javascript(),
				oneDark,
				highlightActiveLine(),
				EditorView.lineWrapping,
				highlightCustomLinesField,
				EditorView.editable.of(isEditable),
				EditorView.updateListener.of((update) => {
					if (update.docChanged) {
						setCode(update.state.doc.toString());
						update.view.dispatch({
							effects: updateDecorationsEffect.of(computeHighlightCustomLines(update.state)),
						});
					}
				}),
			],
		});

		const view = new EditorView({
			state: startState,
			parent: editorRef.current,
		});

		setCursorAtEnd(view);
		viewRef.current = view;

		return () => view.destroy();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (viewRef.current) {
			const editorDomNode = viewRef.current.dom;
			editorDomNode.contentEditable = !isEditable;
		}
	}, [isEditable]);

	useEffect(() => {
		if (viewRef.current && code !== viewRef.current.state.doc.toString()) {
			const transaction = viewRef.current.state.update({
				changes: {
					from: 0,
					to: viewRef.current.state.doc.length,
					insert: code,
				},
			});
			viewRef.current.update([transaction]);
			setCursorAtEnd(viewRef.current);
		}
	}, [code]);

	useEffect(() => {
		setCursorAtEnd(viewRef.current);
	}, [currentQuestionIndex, assistantResponse, focusTrigger]);

	return <div id={id} className={`codeEditor ${className}`} ref={editorRef}></div>;
};

export default CodeEditor;
