import React, { createContext, useContext, useState } from "react";

const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
	const [activeModal, setActiveModal] = useState(null);

	const openModal = (modalId, props) => {
		setActiveModal({
			id: modalId,
			props: {
				...props,
				canBeClosed: props.canBeClosed !== undefined ? props.canBeClosed : true,
			},
		});
	};

	const closeModal = () => {
		setActiveModal(null);
	};

	return <ModalContext.Provider value={{ activeModal, openModal, closeModal }}>{children}</ModalContext.Provider>;
};

export const useModal = () => {
	const context = useContext(ModalContext);
	if (!context) {
		throw new Error("useModal must be used within a ModalProvider");
	}
	return context;
};
