import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import lucaAvatar from "../../assets/images/homepage/testimonials/luca.jpeg";
import logo from "../../assets/images/logo.png";
import video from "../../assets/videos/editor.mp4";
import Footer from "../../components/Footer/Footer";
import Pricing from "./Pricing/Pricing";
import Testimonial from "./Testimonial/Testimonial";
import "./homepage.min.css";

const Index = () => {
	const navigate = useNavigate();
	const [isPlaying, setIsPlaying] = useState(true);
	const videoRef = useRef(null);
	const user = JSON.parse(localStorage.getItem("user"));

	useEffect(() => {
		document.title = "Bite-sized, intelligent JavaScript practice - CodeAcer";
	}, []);

	useEffect(() => {
		if (user) {
			navigate("/home");
		}
	}, [user, navigate]);

	const handlePlayPause = () => {
		if (isPlaying) {
			videoRef.current.pause();
		} else {
			videoRef.current.play();
		}
		setIsPlaying(!isPlaying);
	};

	return (
		<div id="landingPage">
			<div id="landingPageTopContainer">
				<a href="/">
					<img id="logo" src={logo} alt="Logo" />
				</a>
				<div id="buttonsContainer">
					<a
						href="/login"
						data-testid="login"
						className="button button-white button-no-border"
					>
						Log in
					</a>
					<a
						href="/signup"
						data-testid="signup"
						className="button button-white"
					>
						Sign up
					</a>
				</div>
			</div>
			<section id="heroContainer">
				<div className="inner">
					<h1>
						Bite-sized, intelligent
						<br />
						JavaScript practice
					</h1>
					<h2>
						Sharpen your development skills through quick practical challenges.
						Write code, explain your understanding, and get <i> even</i> better
						at coding with instant feedback.
					</h2>
					<a href="/signup" id="getStartedButton" className="button-no-border">
						Start Practicing for Free
					</a>
					<div id="editorAnimationWrapper">
						<div id="editorAnimation">
							<video autoPlay loop muted ref={videoRef}>
								<source src={video} type="video/mp4" />
							</video>
						</div>
						<span id="togglePlayPauseButton" onClick={handlePlayPause}>
							{isPlaying ? "Pause" : "Play"}
						</span>
					</div>
				</div>
			</section>
			<section id="testimonialsContainer">
				<div className="inner">
					<Testimonial
						avatarSrc={lucaAvatar}
						quote="CodeAcer is a game-changer. It challenges you to both write code and explain your understanding in English. This dual approach helps you practice coding while deepening your grasp of key concepts. Highly recommend for anyone serious about honing their programming skills!"
						name="Luca Sato-Nioi"
						position="Senior Engineer"
					/>
				</div>
			</section>
			<section id="pricingContainer">
				<div className="inner">
					<h4>Simple pricing</h4>
					<h5>One-time payments — no subscriptions, no auto-renewal</h5>
					<Pricing />
				</div>
			</section>
			<section id="footerWrapper">
				<div className="inner">
					<Footer />
				</div>
			</section>
		</div>
	);
};

export default Index;
