import axios from "axios";
import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useNotice } from "../context/NoticeContext";
import useAuthContext from "../hooks/useAuthContext";

axios.defaults.withCredentials = true;

const GitHubCallback = () => {
	const navigate = useNavigate();
	const { addNotice } = useNotice();
	const { dispatch } = useAuthContext();

	const handleGithubLogin = useCallback(async () => {
		const urlParams = new URLSearchParams(window.location.search);
		const code = urlParams.get("code");
		console.log("CODE", code);
		if (!code) {
			console.error("GitHub code not found in URL parameters");
			addNotice({
				type: "error",
				message: "GitHub authentication failed. Please try again.",
			});
			navigate("/login");
			return;
		}

		try {
			const tokenResponse = await axios.post(process.env.REACT_APP_REDIRECT_URI, { code }, { withCredentials: true });

			if (!tokenResponse.data || !tokenResponse.data.accessToken || !tokenResponse.data.email) {
				throw new Error("Invalid token response from server");
			}

			const { accessToken, email } = tokenResponse.data;

			const exchangeResponse = await axios.post(`${process.env.REACT_APP_API_URL}/exchange-github-access-token`, {
				token: accessToken,
				email,
			});

			if (!exchangeResponse.data || !exchangeResponse.data.userToken) {
				throw new Error("Failed to exchange GitHub token");
			}

			const { userToken } = exchangeResponse.data;

			const userExistsResponse = await axios.post(
				`${process.env.REACT_APP_API_URL}/checkuserexists`,
				{ type: "github", token: userToken, email },
				{
					headers: { Authorization: `Bearer ${userToken}` },
					withCredentials: true,
				}
			);

			if (!userExistsResponse.data || !userExistsResponse.data.exists) {
				throw new Error("User does not exist or invalid response");
			}

			const { sessionToken, refreshToken } = userExistsResponse.data;

			const userObject = {
				token: sessionToken,
				refreshToken,
				email,
				signupStatus: "active",
			};

			// Set cookies and localStorage
			["sessionToken", "refreshToken"].forEach((tokenName) => {
				Cookies.set(tokenName, userObject[tokenName], {
					sameSite: "strict",
					secure: true,
				});
			});

			Cookies.set("user", JSON.stringify(userObject), {
				sameSite: "strict",
				secure: true,
			});

			localStorage.setItem("user", JSON.stringify(userObject));

			dispatch({
				type: "LOGIN",
				payload: userObject,
			});
			navigate("/home");
		} catch (err) {
			console.error("GitHub login failed:", err.message);
			addNotice({
				type: "error",
				message: "Could not authenticate GitHub user. Please try again.",
			});
			navigate("/login");
		}
	}, [navigate, dispatch, addNotice]);

	useEffect(() => {
		handleGithubLogin();
	}, [handleGithubLogin]);

	return <div>Processing GitHub login...</div>;
};

export default GitHubCallback;
