import io from "socket.io-client";

const socket = io(process.env.REACT_APP_API_URL, {
	reconnection: true,
	reconnectionAttempts: Infinity,
	reconnectionDelay: 1000,
	reconnectionDelayMax: 5000,
	withCredentials: true,
	transports: ["websocket", "polling"],
});

socket.on("connect", () => {
	console.log("Socket connected");
});

socket.on("connect_error", (error) => {
	console.error("Socket connection error:", error);
});

socket.on("disconnect", (reason) => {
	console.log("Socket disconnected:", reason);
});

export default socket;
