import React, { useCallback, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import WelcomePage from "../pages/Welcome/Welcome";
import axiosInstance from "../utils/axiosConfig";

const useUserData = () => {
	const [userData, setUserData] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);

	const fetchUserData = useCallback(async () => {
		try {
			const userString = localStorage.getItem("user");
			if (!userString) {
				setIsLoading(false);
				return;
			}

			const { token } = JSON.parse(userString);
			const { data: user } = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/userdata`, {
				headers: { Authorization: `Bearer ${token}` },
				withCredentials: true,
			});

			if (user) {
				// Fetch billing data if the user has a stripeCustomerId
				// if (user.isPremium) {
				// const { data: billingData } = await axiosInstance.get(
				// 	`${process.env.REACT_APP_API_URL}/nextbillingdate/${user.stripeCustomerId}`,
				// 	{
				// 		headers: { Authorization: `Bearer ${token}` },
				// 		withCredentials: true,
				// 	},
				// );
				// user.premiumUntil = billingData.nextBillingDate;
				// 	user.isPremium = billingData.isPremium;
				// } else {
				// 	user.isPremium = false;
				// }

				setUserData(user);
			}
		} catch (err) {
			console.error("Error fetching user data", err);
			setError(err.message);
			localStorage.removeItem("user");
		} finally {
			setIsLoading(false);
		}
	}, []);

	useEffect(() => {
		fetchUserData();
	}, [fetchUserData]);

	return { userData, setUserData, isLoading, error };
};

const UserDataFetcher = ({ children }) => {
	const { userData, setUserData, isLoading, error } = useUserData();

	if (isLoading) return null;
	if (error) {
		return <Navigate to="/" replace />;
	}
	if (!userData) return <Navigate to="/" replace />;
	if (userData.signupStatus === "pending") return <WelcomePage userData={userData} />;

	return React.cloneElement(children, { userData, setUserData });
};

export default UserDataFetcher;
