import React from "react";
import { useNotice } from "../../context/NoticeContext";
import Notice from "../Notice/Notice";
import "./noticeManager.min.css";

const NoticeManager = () => {
	const { notices, removeNotice } = useNotice();

	return (
		<div className="noticeManager">
			{notices.map(({ id, type, message }) => (
				<Notice key={id} id={id} type={type} message={message} removeNotice={removeNotice} />
			))}
		</div>
	);
};

export default NoticeManager;
