import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useEffect, useMemo, useState } from "react";
import SubscriptionHandler from "../../components/SubscriptionHandler";

import Category from "../../components/Category/Category";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header";
import LoadingSkeleton from "../../components/LoadingSkeleton";
import QuestionModal from "../../components/QuestionModal/QuestionModal";
import { useModal } from "../../context/ModalContext";
import { useNotice } from "../../context/NoticeContext";
import { useStreakManager } from "../../hooks/useStreakManager";
import { fetchCategories, fetchCourses } from "../../utils/apiCalls";
import "./questions.min.css";

const Questions = ({ userData, setUserData }) => {
	const [courseOptions, setCourseOptions] = useState([]);
	const [categories, setCategories] = useState({});
	const [expandedCategories, setExpandedCategories] = useState({});
	const [selectedCourse, setSelectedCourse] = useState({});
	const [isQuestionModalOpen, setQuestionModalOpen] = useState(false);
	const [modalContent, setModalContent] = useState(null);
	const [questionIndex, setQuestionIndex] = useState(0);
	const [loading, setLoading] = useState(false);
	const [lastExpanded, setLastExpanded] = useState(null);
	const { addNotice } = useNotice();
	const [token, setToken] = useState(null);

	useStreakManager();

	const { handleSubscribe } = SubscriptionHandler({
		userData,
		setModalContent,
	});

	const { openModal } = useModal();

	useEffect(() => {
		document.title = "CodeAcer";
	}, []);

	const openSubscribeModal = useCallback(() => {
		openModal("subscribeModal", {
			title: "Purchase Complete Access",
			content: (
				<div className="modal-body-row">
					<p>
						We don't like subscriptions either. No auto-renewal, no recurring
						charges, no surprises.
					</p>
					<ul>
						<li>
							<FontAwesomeIcon icon={faCheck} className="icon" />
							Unlock all exercises
						</li>
						<li>
							<FontAwesomeIcon icon={faCheck} className="icon" />
							Access to continuously updating content
						</li>
						<li>
							<FontAwesomeIcon icon={faCheck} className="icon" />
							No auto-renewal. Renew as required.
						</li>
					</ul>
					<button
						data-testid="purchaseAccess"
						className="button-payment"
						onClick={() => handleSubscribe("monthly")}
					>
						Purchase 1 Month - $12.00
					</button>
				</div>
			),
		});
	}, [openModal, handleSubscribe]);

	const updateQuestionAsAnswered = useCallback((questionIndex) => {
		setCategories((prevCategories) => {
			const categoryId = Object.keys(prevCategories).find((id) =>
				prevCategories[id].questions.some((q) => q.index === questionIndex),
			);
			if (categoryId) {
				const updatedCategory = {
					...prevCategories[categoryId],
					questions: prevCategories[categoryId].questions.map((q) =>
						q.index === questionIndex ? { ...q, isCorrectlyAnswered: true } : q,
					),
				};
				return { ...prevCategories, [categoryId]: updatedCategory };
			}
			return prevCategories;
		});
	}, []);

	const handleCourseChange = useCallback(
		(e) => {
			const selectedId = e.target.dataset.value;
			const newSelectedCourse = courseOptions.find(
				(course) => course.id === selectedId,
			);
			setSelectedCourse(newSelectedCourse || {});

			if (newSelectedCourse) {
				localStorage.setItem("selectedCourseId", newSelectedCourse.id);
			}
		},
		[courseOptions],
	);

	const findQuestionByIndex = useCallback(
		(index) => {
			for (const category of Object.values(categories)) {
				const question = category.questions.find((q) => q.index === index);
				if (question) return question;
			}
			return null;
		},
		[categories],
	);

	const goToQuestion = useCallback(
		(direction) => {
			setQuestionIndex((prevIndex) => {
				const currentQuestion = findQuestionByIndex(prevIndex);
				if (currentQuestion) {
					const currentCategoryQuestions =
						categories[currentQuestion.category._id].questions;
					const currentIndexInCategory = currentCategoryQuestions.findIndex(
						(q) => q.index === prevIndex,
					);

					let newIndex;
					if (direction === "next") {
						const nextIndexInCategory = currentIndexInCategory + 1;
						if (nextIndexInCategory < currentCategoryQuestions.length) {
							newIndex = currentCategoryQuestions[nextIndexInCategory].index;
						} else {
							const categoryIds = Object.keys(categories);
							const currentCategoryIndex = categoryIds.indexOf(
								currentQuestion.category._id,
							);
							let nextCategoryIndex = currentCategoryIndex + 1;
							while (nextCategoryIndex < categoryIds.length) {
								const nextCategoryQuestions =
									categories[categoryIds[nextCategoryIndex]].questions;
								if (nextCategoryQuestions.length > 0) {
									newIndex = nextCategoryQuestions[0].index;
									break;
								}
								nextCategoryIndex++;
							}
							if (newIndex === undefined) {
								const firstCategoryId = categoryIds[0];
								const firstCategory = categories[firstCategoryId];
								if (firstCategory && firstCategory.questions.length > 0) {
									newIndex = firstCategory.questions[0].index;
								}
							}
						}
					} else if (direction === "previous") {
						const prevIndexInCategory = currentIndexInCategory - 1;
						if (prevIndexInCategory >= 0) {
							newIndex = currentCategoryQuestions[prevIndexInCategory].index;
						} else {
							const categoryIds = Object.keys(categories);
							const currentCategoryIndex = categoryIds.indexOf(
								currentQuestion.category._id,
							);
							let prevCategoryIndex = currentCategoryIndex - 1;
							while (prevCategoryIndex >= 0) {
								const prevCategoryQuestions =
									categories[categoryIds[prevCategoryIndex]].questions;
								if (prevCategoryQuestions.length > 0) {
									newIndex =
										prevCategoryQuestions[prevCategoryQuestions.length - 1]
											.index;
									break;
								}
								prevCategoryIndex--;
							}
							if (newIndex === undefined) {
								const lastCategoryId = categoryIds[categoryIds.length - 1];
								const lastCategory = categories[lastCategoryId];
								if (lastCategory && lastCategory.questions.length > 0) {
									newIndex =
										lastCategory.questions[lastCategory.questions.length - 1]
											.index;
								}
							}
						}
					}

					return newIndex !== undefined ? newIndex : prevIndex;
				}
				return prevIndex;
			});
		},
		[categories, findQuestionByIndex],
	);

	const toggleCategoryExpanded = useCallback((categoryId) => {
		setExpandedCategories((prev) => {
			const newState = !prev[categoryId];
			const newExpandedCategories = {};

			for (const id in prev) {
				newExpandedCategories[id] = false;
			}

			if (newState) {
				newExpandedCategories[categoryId] = true;
				setLastExpanded(categoryId);
			}

			return newExpandedCategories;
		});
	}, []);

	const closeQuestionModal = useCallback(() => {
		setQuestionModalOpen(false);
	}, []);

	const checkIfUserHasAccess = useCallback(
		(content) => {
			const isPremiumCategory = content.category.premium;
			const hasAccess = userData.isPremium || !isPremiumCategory;

			if (!hasAccess) {
				openSubscribeModal();

				if (isQuestionModalOpen) {
					closeQuestionModal();
				}

				return false;
			}

			return true;
		},
		[userData, closeQuestionModal, isQuestionModalOpen, openSubscribeModal],
	);

	const defineStartingCode = useMemo(
		() => (content) => {
			if (!content) return "";
			if (content.genre === "code") {
				const spacing = content.startingCode ? "\n\n" : "";
				return `${content.startingCode}${spacing}`;
			}
		},
		[],
	);

	const openQuestionModal = useCallback(
		(content) => {
			try {
				if (!checkIfUserHasAccess(content)) {
					return;
				}

				setQuestionIndex(content.index);
				setModalContent(content);
				setQuestionModalOpen(true);
			} catch (error) {
				console.error("Error occurred while opening question modal:", error);
				addNotice({
					type: "error",
					message:
						"An error occurred while trying to open the question. Please try again later.",
				});
			}
		},
		[addNotice, checkIfUserHasAccess],
	);

	const fetchAndSetCategories = useCallback(async () => {
		setLoading(true);
		if (
			userData &&
			userData.sessionToken &&
			selectedCourse &&
			selectedCourse.id
		) {
			try {
				const fetchedCategories = await fetchCategories(
					userData.sessionToken,
					selectedCourse.id,
				);
				setCategories(fetchedCategories);
			} catch (error) {
				console.error("Failed to fetch categories:", error);
			} finally {
				setLoading(false);
			}
		} else {
			setLoading(false);
		}
	}, [userData, selectedCourse]);

	useEffect(() => {
		const userString = localStorage.getItem("user");
		if (userString) {
			try {
				const parsedUserData = JSON.parse(userString);
				const parsedToken = parsedUserData.token;
				setToken(parsedToken);
			} catch (error) {
				console.error("Error parsing user data:", error);
			}
		}
	}, [loading, userData]);

	useEffect(() => {
		const savedCourseId = localStorage.getItem("selectedCourseId");

		const fetchAndSetCourses = async () => {
			setLoading(true);
			if (token) {
				try {
					const fetchedCourses = await fetchCourses(token);
					if (fetchedCourses && Array.isArray(fetchedCourses)) {
						const formattedCourses = fetchedCourses.map((course) => ({
							id: course._id,
							title: course.title,
							image: course.image,
						}));

						setCourseOptions(formattedCourses);

						if (savedCourseId) {
							const savedCourse = formattedCourses.find(
								(course) => course.id === savedCourseId,
							);
							setSelectedCourse(savedCourse || formattedCourses[0] || {});
						} else {
							setSelectedCourse(formattedCourses[0] || {});
						}
					}
				} catch (error) {
					console.error("Failed to fetch courses:", error);
				} finally {
					setLoading(false);
				}
			} else {
				setLoading(false);
			}
		};

		fetchAndSetCourses();
	}, [token]);

	useEffect(() => {
		if (token && selectedCourse && selectedCourse.id) {
			fetchAndSetCategories();
		}
	}, [token, selectedCourse, fetchAndSetCategories]);

	useEffect(() => {
		if (modalContent && questionIndex === modalContent.index) {
			return;
		}

		const newQuestionContent = findQuestionByIndex(questionIndex);
		if (newQuestionContent) {
			setModalContent(newQuestionContent);
		}
	}, [questionIndex, findQuestionByIndex, modalContent]);

	useEffect(() => {
		if (lastExpanded) {
			const element = document.getElementById(lastExpanded);
			if (element) {
				const y = element.getBoundingClientRect().top + window.scrollY - 64;
				window.scrollTo({ top: y });
			}
		}
	}, [lastExpanded]);

	const categoriesArray = useMemo(
		() => Object.entries(categories),
		[categories],
	);

	return (
		<div id="questionsContainer">
			<div id="questionsContainerInner">
				<Header
					selectedCourse={selectedCourse}
					courseOptions={courseOptions}
					handleCourseChange={handleCourseChange}
					userData={userData}
					setUserData={setUserData}
					openSubscribeModal={openSubscribeModal}
				/>
				{loading ? (
					<LoadingSkeleton />
				) : (
					<>
						<div id="categoriesContainer">
							{categoriesArray.map(([categoryId, categoryData]) => (
								<Category
									key={categoryId}
									categoryId={categoryId}
									categoryData={categoryData}
									expandedCategories={expandedCategories}
									toggleCategoryExpanded={toggleCategoryExpanded}
									openQuestionModal={openQuestionModal}
									categoryIndex={categoryData.index}
									token={token}
									userData={userData}
								/>
							))}
						</div>
						<Footer />
					</>
				)}
			</div>
			<QuestionModal
				courseId={selectedCourse.id}
				isOpen={isQuestionModalOpen}
				closeQuestionModal={closeQuestionModal}
				content={modalContent}
				defineStartingCode={defineStartingCode}
				currentQuestionIndex={questionIndex}
				goToQuestion={goToQuestion}
				updateQuestionAsAnswered={updateQuestionAsAnswered}
				categories={categories}
				loading={loading}
				setLoading={setLoading}
				checkIfUserHasAccess={checkIfUserHasAccess}
				userData={userData}
			/>
		</div>
	);
};

export default Questions;
