import { faXmark } from "@fortawesome/pro-regular-svg-icons"; // Import the specific icon
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "./notice.min.css";

const Notice = ({ id, type, message, removeNotice }) => (
	<div className={`notice ${type}`} key={id}>
		{message}
		<FontAwesomeIcon icon={faXmark} className="close" onClick={() => removeNotice(id)} />
	</div>
);

export default Notice;
