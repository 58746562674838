import { faCircleCheck } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "./pricing.min.css";

const PricingCard = ({ title, price, features, buttonClass }) => (
	<div className="pricingCard">
		<h3>{title}</h3>
		<p className="price">{price}</p>
		<ul>
			{features.map((feature, index) => (
				<li key={index}>
					<FontAwesomeIcon icon={faCircleCheck} className="icon" />
					{feature}
				</li>
			))}
		</ul>
		<a href="/signup" className={`button button-${buttonClass}`}>
			Sign up
		</a>
	</div>
);

const PricingCards = () => {
	const cards = [
		{
			title: "Basic",
			price: "Free for all users",
			features: [
				"Select coding tasks",
				"Select analysis tasks",
				"Access to all skill levels",
			],
			buttonClass: "primary",
		},
		{
			title: "Full Access",
			price: "$12 for 1 month",
			features: [
				"Everything in Free",
				"Access to all tasks",
				"No rate limits",
				"No auto-renewal",
			],
			buttonClass: "yellow",
		},
	];

	return (
		<div className="pricingCards">
			{cards.map((card, index) => (
				<PricingCard key={index} {...card} />
			))}
		</div>
	);
};

export default PricingCards;
