import {
	faArrowRotateLeft,
	faBroomWide,
	faChevronLeft,
	faChevronRight,
	faPenToSquare,
	faSpinnerThird,
	faThumbsDown,
	faXmark,
} from "@fortawesome/pro-regular-svg-icons";
import { faCirclePlay, faComment, faForward } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Tooltip } from "react-tooltip";
import correctSound from "../../assets/sounds/correct.wav";
import incorrectSound from "../../assets/sounds/incorrect.wav";
import { useConsecutiveContext } from "../../context/ConsecutiveContext";
import { useModal } from "../../context/ModalContext";
import { useNotice } from "../../context/NoticeContext";
import { submitCode } from "../../utils/apiCalls";
import { generateUniqueId } from "../../utils/helpers";
import { disableScroll, enableScroll } from "../../utils/scrollLock";
import CodeEditor from "../CodeEditor/CodeEditor";
import QuestionAndAnswerEdit from "../QuestionAndAnswerEdit";
import "./questionModal.min.css";

const QuestionModal = ({
	courseId,
	isOpen,
	closeQuestionModal,
	content,
	defineStartingCode,
	currentQuestionIndex,
	goToQuestion,
	updateQuestionAsAnswered,
	categories,
	loading,
	setLoading,
	checkIfUserHasAccess,
	userData,
}) => {
	const [feedback, setFeedback] = useState("");
	const [displayfeedbackButton, setDisplayFeedbackButton] = useState(false);
	const [isCorrect, setIsCorrect] = useState(false);
	const [categoryProgress, setCategoryProgress] = useState(0);
	const [code, setCode] = useState("");
	const [reportSubmitted, setReportSubmitted] = useState(false);
	const [focusTrigger, setFocusTrigger] = useState(0);
	const { openModal } = useModal();
	const { addNotice } = useNotice();
	const { setCounter } = useConsecutiveContext();

	const initialCode = useMemo(() => defineStartingCode(content), [content, defineStartingCode]);

	const submittedIdRef = useRef(null);

	const enableCodeSubmit = useMemo(() => {
		return code && code !== initialCode && !loading;
	}, [code, initialCode, loading]);

	const openAdminEditModal = useCallback(() => {
		if (userData.isAdmin && content) {
			openModal("adminEditQuestion", {
				title: "Edit Question",
				content: (
					<QuestionAndAnswerEdit
						questionId={content._id}
						initialQuestion={content.question}
						initialAnswer={content.answer}
						initialTitle={content.title}
						initialStartingCode={content.startingCode}
						token={userData.sessionToken}
						streakDays={userData.streakDays}
					/>
				),
			});
		}
	}, [userData.streakDays, userData.isAdmin, content, userData.sessionToken, openModal]);

	const scrollToBottom = () => {
		const questionModal = document.getElementById("questionModal");
		questionModal.scrollTop = questionModal?.scrollHeight;
	};

	const typeFeedback = useCallback((responseText, currentText = "", currentOperationId) => {
		if (responseText.length > 0 && submittedIdRef.current === currentOperationId) {
			const nextChar = responseText.charAt(0);
			const newText = currentText + nextChar;
			setFeedback(newText);
			setTimeout(() => {
				typeFeedback(responseText.slice(1), newText, currentOperationId);
				scrollToBottom();
			}, 0);
		} else {
			setDisplayFeedbackButton(true);
		}
	}, []);

	const clearFeedback = useCallback(() => {
		setDisplayFeedbackButton(false);
		setFeedback("");
		setReportSubmitted(false);
		setIsCorrect("");
		setFocusTrigger((prev) => prev + 1);
	}, []);

	const resetModal = useCallback(() => {
		setCode(initialCode);
		setIsCorrect("");
		clearFeedback();
		setFocusTrigger((prev) => prev + 1);
		submittedIdRef.current = null;
	}, [initialCode, clearFeedback]);

	// TODO: Return in questions endpoint. No need in making a separate call.
	const handleExampleSolution = async () => {
		try {
			const response = await axios.get(`${process.env.REACT_APP_API_URL}/examplesolution`, {
				params: {
					currentQuestionIndex: currentQuestionIndex,
				},
			});

			const codePrepend = ["// Example Solution:", "", ""].join("\n");
			const analysisPrepend = "/*\n\n";
			const prepend = content.genre === "code" ? codePrepend : analysisPrepend;
			const append = content.genre === "analysis" ? "\n\n*/" : "";
			const exampleSolution = response.data.example;

			typeFeedback(`${prepend}${exampleSolution}${append}`, "", submittedIdRef.current);
			setFocusTrigger((prev) => prev + 1);
		} catch (error) {
			console.error("Error fetching the example solution:", error);
			addNotice({
				type: "error",
				message: "Error fetching the example solution. Please try again.",
			});
		}
	};

	const useKeyboardShortcut = (key, ctrlKey, callback) => {
		useEffect(() => {
			const handleKeyDown = (event) => {
				if (event.ctrlKey === ctrlKey && event.key.toLowerCase() === key.toLowerCase()) {
					event.preventDefault();
					callback();
				}
			};

			window.addEventListener("keydown", handleKeyDown);

			return () => {
				window.removeEventListener("keydown", handleKeyDown);
			};
		}, [key, ctrlKey, callback]);
	};

	useKeyboardShortcut("r", true, resetModal);

	// TODO: Need's callback
	const handleQuestionNavigation = useCallback(
		(direction) => {
			goToQuestion(direction);
			resetModal();
		},
		[goToQuestion, resetModal]
	);

	const calculateCategoryProgress = useCallback(() => {
		if (content && content.category && categories[content.category._id] && categories[content.category._id].questions) {
			const category = categories[content.category._id];
			const { questions } = category;
			const totalQuestions = questions.length;
			const answeredQuestions = questions.map((q) => q.isCorrectlyAnswered);
			const totalCorrectlyAnswered = answeredQuestions.filter(Boolean).length;
			return {
				progress: (totalCorrectlyAnswered / totalQuestions) * 100,
				totalQuestions,
				answeredQuestions,
			};
		}
		return { progress: 0, totalQuestions: 0, answeredQuestions: [] };
	}, [content, categories]);

	const throwConfetti = (id) => {
		const rect = document.getElementById(id).getBoundingClientRect();
		const x = rect.left + rect.width / 2;
		const y = rect.top + rect.height / 2;

		// eslint-disable-next-line no-undef
		confetti({
			particleCount: 75,
			startVelocity: 15,
			spread: 360,
			origin: { x: x / window.innerWidth, y: y / window.innerHeight },
			decay: 0.9,
			gravity: 0.3,
			zIndex: 1051,
		});
	};

	const handleSubmit = useCallback(async () => {
		if (!enableCodeSubmit) return;

		const currentOperationId = generateUniqueId();
		submittedIdRef.current = currentOperationId;

		setIsCorrect("");
		setLoading(true);
		clearFeedback();

		// Assistant response
		try {
			const response = await submitCode(courseId, code, currentQuestionIndex, userData.sessionToken);

			if (currentOperationId !== submittedIdRef.current) {
				return;
			}

			const { isCorrect, feedback, streakDays } = response;

			if (streakDays !== undefined) {
				setCounter(streakDays);
				localStorage.setItem("userStreak", response.streakDays.toString());
				localStorage.setItem("userStreakTimestamp", new Date().toISOString());
			}

			const syntax = isCorrect ? "// ✓" : "// ✗";
			const codeResponseText = `${syntax} ${feedback}`;
			const analysisResponseText = `${syntax} ${feedback}`;

			let responseText = ["/* ---------- */", "/* EVALUATION */", "/* ---------- */", "\n"].join("\n");

			if (content.genre === "code") {
				responseText += codeResponseText;
			} else if (content.genre === "analysis") {
				responseText += analysisResponseText;
			}

			typeFeedback(responseText, "", currentOperationId);
			setIsCorrect(isCorrect);

			let sound;

			if (isCorrect) {
				sound = correctSound;
				throwConfetti("userEditor");
			} else {
				sound = incorrectSound;
			}

			const audio = new Audio(sound);
			audio.volume = 0.5;
			audio.play();

			if (isCorrect && !content.isCorrectlyAnswered) {
				updateQuestionAsAnswered(currentQuestionIndex);
			}
		} catch (error) {
			// TODO: Need to handle this error better
			if (error.response.data === "Too many submissions made.") {
				addNotice({
					type: "error",
					message: "You have reached your submission limit. Subscribe for near limitless submissions.",
				});
			}
		}

		setLoading(false);
	}, [
		courseId,
		code,
		currentQuestionIndex,
		setLoading,
		content,
		setIsCorrect,
		updateQuestionAsAnswered,
		typeFeedback,
		addNotice,
		enableCodeSubmit,
		userData.sessionToken,
		clearFeedback,
		setCounter,
	]);

	const handleCtrlEnter = useCallback(() => {
		if (isCorrect === true) {
			handleQuestionNavigation("next");
		} else if (enableCodeSubmit) {
			handleSubmit();
		}
	}, [isCorrect, enableCodeSubmit, handleSubmit, handleQuestionNavigation]);

	const wrapInCodeTag = (str) => {
		const parts = str.split(/(`[^`]+`)/g);
		return parts.map((part, index) => {
			if (index % 2 === 1) {
				return <code key={index}>{part.slice(1, -1)}</code>;
			}
			return part;
		});
	};

	useEffect(() => {
		if (content && isOpen) {
			checkIfUserHasAccess(content);
		}
	}, [checkIfUserHasAccess, content, isOpen]);

	useEffect(() => {
		setCode(initialCode);
	}, [initialCode, currentQuestionIndex]);

	useEffect(() => {
		if (isOpen) {
			disableScroll();
			setIsCorrect("");
			clearFeedback();
			submittedIdRef.current = null;
		}
		return () => {
			if (isOpen) {
				enableScroll();
			}
		};
	}, [isOpen, clearFeedback]);

	useEffect(() => {
		if (content && content.category) {
			setCategoryProgress(calculateCategoryProgress);
		}
	}, [categories, content, calculateCategoryProgress]);

	useEffect(() => {
		if (isOpen) {
			document.title = `${content.title} - CodeAcer`;
		} else {
			document.title = "CodeAcer";
		}
	}, [isOpen, content]);

	const handleReportSubmit = async () => {
		const config = {
			headers: {
				Authorization: `Bearer ${userData.sessionToken}`,
			},
		};

		const questionIndex = currentQuestionIndex;
		const userAnswer = code;
		const aiResponse = feedback.replace(/\/\*[-\s]*\*\/\s*\/\*\s*ASSISTANT\s*\*\/\s*\/\*[-\s]*\*\//g, "").trim();

		try {
			const response = await axios.post(
				`${process.env.REACT_APP_API_URL}/reportanissue`,
				{ questionIndex, userAnswer, aiResponse },
				config
			);
			if (response.status === 201) {
				addNotice({
					type: "success",
					message: "Thanks for the feedback. We use this information to improve our model.",
				});
				setReportSubmitted(true);
			} else {
				addNotice({
					type: "error",
					message: "Failed to submit your report. Please try again.",
				});
			}
		} catch (error) {
			console.error(error);
		}
	};

	if (isOpen) {
		const categoryTitle = content.category.title;
		const { question } = content;

		return (
			<div id="questionModal">
				<div id="questionModalTopNav">
					<div id="questionModalTopNavLeft">
						<button
							data-testid="closeQuestion"
							id="closeQuestionButton"
							className="button-icon"
							onClick={closeQuestionModal}
						>
							<FontAwesomeIcon icon={faXmark} className="icon" />
						</button>
					</div>
					<div id="questionModalTopNavCentre">
						<button
							onClick={() => handleQuestionNavigation("previous")}
							className="navigationButton button-no-border"
							disabled={loading}
						>
							<FontAwesomeIcon icon={faChevronLeft} className="icon" />
						</button>
						<div id="categoryProgress">
							{Object.values(categories[content.category._id]?.questions || []).map((question) => (
								<div
									key={question._id}
									className={`questionSection ${question.isCorrectlyAnswered ? "completed" : ""} ${
										question.index === currentQuestionIndex ? "current" : ""
									}`}
									style={{ width: `${100 / categoryProgress.totalQuestions}%` }}
								/>
							))}
						</div>
						<button
							onClick={() => handleQuestionNavigation("next")}
							className="navigationButton button-no-border"
							disabled={loading}
						>
							<FontAwesomeIcon icon={faChevronRight} className="icon" />
						</button>
					</div>
					<div id="questionModalTopNavRight">
						{userData.isAdmin === true && (
							<>
								<button id="adminEditQuestionButton" className="button-icon" onClick={openAdminEditModal}>
									<FontAwesomeIcon icon={faPenToSquare} className="icon" />
								</button>
							</>
						)}
						<button
							id="resetQuestionButton"
							className="button-icon"
							onClick={resetModal}
							data-tooltip-id="resetQuestionTooltip"
							data-tooltip-content="Reset code editor (ctrl + r)"
						>
							<FontAwesomeIcon icon={faArrowRotateLeft} className="icon" />
						</button>
						<Tooltip id="resetQuestionTooltip" className="tooltip" place="bottom" />
					</div>
				</div>
				<div id="questionModalInner">
					<div id="modalContent">
						<h4> {categoryTitle}</h4>
						<h3>{wrapInCodeTag(question)}</h3>
						<div className="codeEditorWrapper">
							{content.genre === "analysis" && content.startingCode && (
								<CodeEditor
									className="startingCodeEditor"
									code={content.startingCode}
									setCode={() => {}}
									isEditable={false}
								/>
							)}
							<CodeEditor
								id="userEditor"
								data-testid="userEditor"
								className={`userEditor ${content.genre}Editor`}
								code={code}
								feedback={feedback}
								setCode={setCode}
								currentQuestionIndex={currentQuestionIndex}
								handleCtrlEnter={handleCtrlEnter}
								isEditable={!loading}
								focusTrigger={focusTrigger}
							/>
							{feedback && (
								<>
									<CodeEditor className="assistantEditor" code={feedback} setCode={() => {}} isEditable={false} />
									{displayfeedbackButton && !isCorrect && (
										<div id="assistantButtons">
											<button data-testid="clearResponse" id="clearFeedbackButton" onClick={clearFeedback}>
												<FontAwesomeIcon icon={faBroomWide} className="icon" />
												Clear Response
											</button>
											<button
												data-testid="reportBtn"
												id="reportResponseButton"
												onClick={handleReportSubmit}
												className={reportSubmitted ? "reported" : ""}
											>
												<FontAwesomeIcon icon={faThumbsDown} className="icon" />
												Report
											</button>
										</div>
									)}
								</>
							)}
						</div>
						<div id="shortcutIndicator" className={`${enableCodeSubmit ? "highlighted" : ""}`}>
							Use <code>ctrl + enter</code> to {isCorrect === true ? "continue" : "submit"}
						</div>
						<div id="questionModalButtonContainer">
							{isCorrect !== true && (
								<button onClick={handleExampleSolution} className={`button-example button-ds`} disabled={loading}>
									<FontAwesomeIcon icon={faComment} />
									Example
								</button>
							)}
							{isCorrect !== true && (
								<button
									onClick={() => handleQuestionNavigation("next")}
									className={`button-skip button-ds`}
									disabled={loading}
								>
									<FontAwesomeIcon icon={faForward} />
									Skip
								</button>
							)}
							{isCorrect !== true && (
								<button
									onClick={handleSubmit}
									className={`button-positive button-ds ${!enableCodeSubmit ? "disabled" : ""}`}
									disabled={loading}
								>
									<FontAwesomeIcon icon={faCirclePlay} />
									Check
									{loading && <FontAwesomeIcon icon={faSpinnerThird} spin />}
								</button>
							)}
							{isCorrect === true && (
								<button className="button-positive button-ds" onClick={() => handleQuestionNavigation("next")}>
									<FontAwesomeIcon icon={faCirclePlay} />
									Next Question
								</button>
							)}
						</div>
					</div>
				</div>
			</div>
		);
	}
};

export default QuestionModal;
