import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import { useCallback } from "react";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const useSubscriptionHandler = (userData, setModalContent) => {
	const handleSubscribe = useCallback(
		async (planType) => {
			try {
				const response = await axios.post(`${process.env.REACT_APP_API_URL}/checkusersubstatus`, {
					email: userData.email,
				});

				if (response.data.subscribed === false) {
					const priceId = process.env.REACT_APP_STRIPE_MONTHLY_PRICE_ID;
					// planType === "monthly"
					// 	? process.env.REACT_APP_STRIPE_MONTHLY_PRICE_ID
					// 	: process.env.REACT_APP_STRIPE_ANNUAL_PRICE_ID;
					const sessionResponse = await axios.post(`${process.env.REACT_APP_API_URL}/create-checkout-session`, {
						userId: userData.userData._id,
						priceId: priceId,
					});
					const sessionId = sessionResponse.data.id;
					const stripe = await stripePromise;
					await stripe.redirectToCheckout({ sessionId });
				}
			} catch (error) {
				console.error("Error in handleSubscribe:", error);
			}
			setModalContent({ isOpen: false });
		},
		[userData, setModalContent]
	);

	return { handleSubscribe };
};

export default useSubscriptionHandler;
