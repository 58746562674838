import React from "react";
import "./testimonial.min.css";

const Testimonial = ({ avatarSrc, quote, name, position }) => {
	return (
		<div className="testimonial">
			<div className="content">
				<div className="quote">{quote}</div>
				<div className="author">
					<img className="avatar" src={avatarSrc} alt={name} />
					<div className="nameAndPosition">
						<p className="name">{name}</p>
						<p className="position">{position}</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Testimonial;
