import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNotice } from "../context/NoticeContext";

const QuestionAndAnswerEdit = ({
	questionId,
	token,
	initialQuestion,
	initialAnswer,
	initialTitle,
	initialStartingCode,
}) => {
	const [question, setQuestion] = useState(initialQuestion || "");
	const [answer, setAnswer] = useState(initialAnswer || "");
	const [title, setTitle] = useState(initialTitle || "");
	const [startingCode, setStartingCode] = useState(initialStartingCode || "");

	const { addNotice } = useNotice();

	// Add useEffect to update state when props change
	useEffect(() => {
		setQuestion(initialQuestion || "");
		setAnswer(initialAnswer || "");
		setTitle(initialTitle || "");
		setStartingCode(initialStartingCode || "");
	}, [initialQuestion, initialAnswer, initialTitle, initialStartingCode]);

	const handleSubmit = async (e) => {
		e.preventDefault();

		const updateData = {
			question,
			answer,
			title,
			startingCode,
		};

		try {
			await axios.put(`${process.env.REACT_APP_API_URL}/admineditquestion/${questionId}`, updateData, {
				headers: { Authorization: `Bearer ${token}` },
			});
			addNotice({
				type: "success",
				message: "Question updated successfully",
			});
		} catch (error) {
			addNotice({
				type: "error",
				message: "Error updating question",
			});
		}
	};

	return (
		<form onSubmit={handleSubmit}>
			<div class="input-group">
				<h3>Title</h3>
				<input type="text" placeholder="Title" value={title} onChange={(e) => setTitle(e.target.value)} />
			</div>
			<div class="input-group">
				<h3>Question</h3>
				<textarea placeholder="Question" rows="2" value={question} onChange={(e) => setQuestion(e.target.value)} />
			</div>
			<div class="input-group">
				<h3>Starting Code</h3>
				<textarea
					placeholder="Starting Code"
					rows="3"
					value={startingCode}
					onChange={(e) => setStartingCode(e.target.value)}
				/>
			</div>
			<div class="input-group">
				<h3>Answer</h3>
				<textarea placeholder="Answer" rows="3" value={answer} onChange={(e) => setAnswer(e.target.value)} />
			</div>
			<button type="submit">Update</button>
		</form>
	);
};

export default QuestionAndAnswerEdit;
