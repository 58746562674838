import axios from "axios";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useNotice } from "../../context/NoticeContext";

const Confirm = () => {
	const { token } = useParams();
	const navigate = useNavigate();
	const { addNotice } = useNotice();

	useEffect(() => {
		const verifyEmail = async () => {
			try {
				const response = await axios.post(
					`${process.env.REACT_APP_API_URL}/confirmemail/${token}`,
				);

				// If the backend returns user data with auth token
				if (response.data.user) {
					localStorage.setItem("user", JSON.stringify(response.data.user));
				}

				addNotice({
					type: "success",
					message: response.data.message,
				});

				// Only navigate to home if we have valid auth
				const userData = localStorage.getItem("user");
				if (userData) {
					navigate("/home");
				} else {
					navigate("/login");
					addNotice({
						type: "info",
						message: "Please log in to continue",
					});
				}
			} catch (error) {
				addNotice({
					type: "error",
					message:
						"Error verifying email. Please try again or contact support.",
				});
				console.error("Error verifying email:", error);
				navigate("/");
			}
		};
		verifyEmail();
	}, []);

	return <></>;
};
export default Confirm;
