import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-solid-svg-icons";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Logo from "../../assets/images/logo.png";
import Footer from "../../components/Footer/Footer";
import { useNotice } from "../../context/NoticeContext";
import "./welcome.min.css";

const WelcomePage = ({ userData }) => {
	const [userEmailLocalStorage, setUserEmailLocalStorage] = useState(null);
	const [loading, setLoading] = useState(false);

	const { addNotice } = useNotice();
	const userEmail = userData.email;

	useEffect(() => {
		const userString = localStorage.getItem("user");
		if (userString) {
			try {
				const user = JSON.parse(userString);
				const email = user.email;
				setUserEmailLocalStorage(email);
			} catch (error) {
				console.error("Error parsing user data:", error);
			}
		}
	}, []);

	const config = {
		headers: {
			Authorization: `Bearer ${userData?.token}`,
		},
		withCredentials: true,
	};

	const handleLogout = async () => {
		try {
			await axios.post(`${process.env.REACT_APP_API_URL}/logout`, {}, config);
		} catch (err) {
			console.error(err);
		}
	};

	const handleResendEmail = async () => {
		try {
			setLoading(true);
			const response = await axios.post(
				`${process.env.REACT_APP_API_URL}/resendemail`,
				{ email: userEmail },
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`,
					},
				}
			);

			if (response.status === 200) {
				setLoading(false);
				addNotice({
					type: "success",
					message: "Confirmation email has been resent. Please check your inbox.",
				});
			}
		} catch (err) {
			console.error(err);
			setLoading(false);
			addNotice({
				type: "error",
				message: "An error occurred while resending the email. Please try again.",
			});
		}
	};

	return (
		<div id="welcomePageContainer">
			<img src={Logo} id="logo" alt="CodeAcer Logo" onClick={handleLogout} />
			<div id="welcomePageContainerInner">
				<h1>Welcome to CodeAcer 🎉</h1>
				<p>
					We've sent a confirmation email to {userData.userEmail || userEmailLocalStorage}.<br></br>Please check your inbox
					and click on the link to activate your account.
				</p>
				<p id="emailNotReceivedText">
					Didn't receive the email?&nbsp;
					<span
						id="clickHere"
						onClick={loading ? null : handleResendEmail}
						style={{ pointerEvents: loading ? "none" : "auto" }}
					>
						Click here
					</span>
					&nbsp;to resend.&nbsp;&nbsp;
					{loading && <FontAwesomeIcon icon={faSpinnerThird} spin />}
				</p>
			</div>
			<Footer />
		</div>
	);
};

export default WelcomePage;
