import React, { createContext, useContext, useEffect, useRef, useState } from "react";

const ConsecutiveContext = createContext();

export const ConsecutiveProvider = ({ children }) => {
	const [counter, setCounter] = useState(() => {
		return 0;
	});
	const initializationCount = useRef(0);

	useEffect(() => {
		initializationCount.current += 1;
	}, []);

	const contextValue = React.useMemo(
		() => ({
			counter,
			setCounter: (newValue) => {
				setCounter(newValue);
			},
		}),
		[counter]
	);

	return <ConsecutiveContext.Provider value={contextValue}>{children}</ConsecutiveContext.Provider>;
};

export const useConsecutiveContext = () => {
	const context = useContext(ConsecutiveContext);
	if (context === undefined) {
		throw new Error("useConsecutiveContext must be used within a ConsecutiveProvider");
	}
	return context;
};
